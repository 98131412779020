import React, { useState, useRef, useEffect } from 'react';
import { TFunction } from 'i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import * as EmailValidator from 'email-validator';
import qs from 'qs';

import Input, { InputType } from '../primitives/Input/Input';
import Button from '../primitives/Button/Button';
import TextArea from '../primitives/TextArea/TextArea';
import { IconTypes } from '../Icon/Icon';
import { useLocaleContext } from '../../context/Locale';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import CommonData from '../../data/common/common';
import ButtonLink, { ButtonLinkSize } from '../ButtonLink/ButtonLink';

import './style.scss';

export enum FormFor {
  renewal = 'renewal',
  refinance = 'internalRefinance',
}

interface OwnProps {
  t: TFunction;
  formFor: FormFor;
}

enum FormDataKey {
  name = 'name',
  email = 'email',
  inquiryRegarding = 'inquiryRegarding',
  message = 'message',
  recaptcha = 'reCaptcha',
}

type Props = OwnProps;

const ResidentialMortgagesForm: React.FC<Props> = ({ t, formFor }) => {
  const { activeLocaleTag } = useLocaleContext();
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const reCaptchaTimerRef = useRef('');

  const inquiryOptions = {
    renewal: `012f1000000LJKD`,
    internalRefinance: `0125o0000004Imy`,
  };

  const yesLabel = t('residentialMortgages.form.yesLabel');
  const noLabel = t('residentialMortgages.form.noLabel');

  const initialFormState = {
    name: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },
    htbClient: {
      isInvalid: false,
      value: '',
      validationMessage: '',
    },
    leverageHome: {
      isInvalid: false,
      value: noLabel,
      validationMessage: '',
    },
    email: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },
    inquiryRegarding: {
      isInvalid: false,
      validationMessage: '',
      value: inquiryOptions[formFor],
    },
    message: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },
    reCaptcha: {
      isInvalid: false,
      validationMessage: '',
      value: '',
      ts: '',
    },
  };

  const [formValues, setFormValues] = useState(initialFormState);
  const [areFormValuesInvalid, setAreFormValuesInvalid] = useState(false);
  const [formSubmissionResponse, setFormSubmissionResponse] = useState({
    submitted: false,
    shouldDisplayMessage: false,
  });

  const handleFormChange = (val: any, id: FormDataKey) => {
    setFormValues({
      ...formValues,
      [id]: {
        ...formValues[id],
        value: val,
      },
    });
  };

  const timestamp = () => {
    const response = document.getElementById('g-recaptcha-response');
    // @ts-ignore
    // eslint-disable-next-line eqeqeq
    if (response == null || response.value.trim() == '') {
      reCaptchaTimerRef.current = JSON.stringify(new Date().getTime());
    }
  };

  const handleCaptchaOnChange = (value: any) => {
    setFormValues((prevState) => ({
      ...prevState,
      reCaptcha: {
        ...prevState.reCaptcha,
        isInvalid: false,
        value,
      },
    }));
  };

  useEffect(() => {
    const interval = setInterval(timestamp, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (formSubmissionResponse.shouldDisplayMessage && formSubmissionResponse.submitted) {
      const message = document.getElementById('success-message');

      if (message) {
        message.scrollIntoView({ block: 'end' });
      }
    }
  }, [formSubmissionResponse]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const newFormValues = { ...formValues };
    const formValueFields = Object.keys(newFormValues);
    let firstErrorField = '';

    formValueFields.forEach((element) => {
      const currentField = newFormValues[element as FormDataKey];

      if (!currentField.value) {
        currentField.isInvalid = true;
        if (!firstErrorField) firstErrorField = element;

        currentField.validationMessage = t(
          `residentialMortgages.form.${element}.validationMessage.emptyField`
        );
      } else if (element === 'email' && !EmailValidator.validate(currentField.value)) {
        currentField.isInvalid = true;
        if (!firstErrorField) firstErrorField = element;

        currentField.validationMessage = t(
          'residentialMortgages.form.email.validationMessage.invalidCharacters'
        );
      } else {
        currentField.isInvalid = false;
        currentField.validationMessage = '';
      }
    });

    const newAreFormValuesInvalid = formValueFields.some(
      (field) => (newFormValues as any)[field].isInvalid
    );

    setFormValues(newFormValues);
    setAreFormValuesInvalid(newAreFormValuesInvalid);

    if (!newAreFormValuesInvalid) {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }

      const description = [
        `${t('residentialMortgages.form.htbClient.label')}: ${formValues.htbClient.value}`,
        `${t('residentialMortgages.form.leverageHome.label')}: ${formValues.leverageHome.value}`,
        formValues.message.value,
      ].join('\n');

      const finalObject = {
        debug: process.env.NODE_ENV === 'development' ? 1 : 0,
        orgid: process.env.GATSBY_WEB_TO_CASE_SALESFORCE_ORG_ID,
        name: formValues.name.value,
        email: formValues.email.value,
        recordType: formValues.inquiryRegarding.value,
        subject: t(`residentialMortgages.form.inquiryRegarding.options.${formFor}`),
        description,
        'g-recaptcha-response': formValues.reCaptcha.value,
        captcha_settings: JSON.stringify({
          keyname: process.env.GATSBY_WEB_TO_CASE_SALESFORCE_CAPTCHA_KEY_NAME,
          fallback: 'true',
          orgId: process.env.GATSBY_WEB_TO_CASE_SALESFORCE_ORG_ID,
          ts: reCaptchaTimerRef.current, // inclusion of time means response was validated
        }),
        submit: 'Submit',
      };

      await fetch(process.env.GATSBY_WEB_TO_CASE_SALESFORCE_URL as string, {
        method: 'POST',
        body: qs.stringify(finalObject),
        credentials: 'same-origin',
        mode: 'no-cors',
        // @ts-ignore
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          Accept:
            'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
          Origin: null,
          'Upgrade-Insecure-Requests': 1,
        },
      })
        .then(() => {
          setFormSubmissionResponse({
            submitted: true,
            shouldDisplayMessage: true,
          });

          setFormValues(() => initialFormState);
        })
        .catch((err) => {
          console.error(`Failed: `, err);
          setFormSubmissionResponse({
            submitted: false,
            shouldDisplayMessage: true,
          });
        });

      return true;
    }

    if (firstErrorField) {
      let inputField;

      if (firstErrorField === FormDataKey.recaptcha) {
        inputField = document.querySelector('recaptcha-checkbox-border');
      } else {
        inputField = document.getElementById(firstErrorField);
      }

      if (inputField) {
        (inputField as HTMLElement).focus();
      }
    }

    return false;
  };

  const handleRadioChange = (event: any) => {
    const { name, value } = event.target;

    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name as FormDataKey],
        value,
      },
    });
  };

  return formSubmissionResponse.shouldDisplayMessage && formSubmissionResponse.submitted ? (
    <div className="ResidentialMortgagesForm__generic-gap">
      <p
        className="ResidentialMortgagesForm__success-message"
        aria-live="polite"
        id="success-message"
      >
        {t('residentialMortgages.form.submissionValidation.success')}
      </p>
    </div>
  ) : (
    <form
      onSubmit={handleSubmit}
      className="ResidentialMortgagesForm ResidentialMortgagesForm__generic-gap"
    >
      <input id="inquiryRegarding" type="hidden" value={inquiryOptions[formFor]} />
      <Input
        id="name"
        isInvalid={formValues.name.isInvalid}
        validationMessage={formValues.name.validationMessage}
        options={{
          type: InputType.TEXT,
          label: t('residentialMortgages.form.name.label'),
          inputProps: {
            name: 'name',
            value: formValues.name.value,
          },
        }}
        required
        onChange={handleFormChange}
      />
      <div className="Input Input__Radio">
        <div className="Input__group-wrapper" role="radiogroup" aria-labelledby="htbClient">
          <p id="htbClient">{t('residentialMortgages.form.htbClient.label')}</p>
          <label htmlFor="htbClientYes">
            <input
              type="radio"
              name="htbClient"
              value={t('residentialMortgages.form.yesLabel') as string}
              checked={formValues.htbClient.value === yesLabel}
              onChange={handleRadioChange}
              aria-label={t('residentialMortgages.form.yesLabel')}
            />
            {t('residentialMortgages.form.yesLabel')}
          </label>
          <label htmlFor="htbClientNo">
            <input
              type="radio"
              name="htbClient"
              value={t('residentialMortgages.form.noLabel') as string}
              checked={formValues.htbClient.value === noLabel}
              onChange={handleRadioChange}
              aria-label={t('residentialMortgages.form.noLabel')}
            />
            {t('residentialMortgages.form.noLabel')}
          </label>
        </div>
      </div>
      {formValues.htbClient.value === yesLabel ? (
        <>
          <div className="Input Input__Radio">
            <div className="Input__group-wrapper" role="radiogroup" aria-labelledby="leverageHome">
              <p id="leverageHome">{t('residentialMortgages.form.leverageHome.label')}</p>
              <label htmlFor="leverageHomeYes">
                <input
                  type="radio"
                  name="leverageHome"
                  value={t('residentialMortgages.form.yesLabel') as string}
                  checked={formValues.leverageHome.value === yesLabel}
                  onChange={handleRadioChange}
                  aria-label={t('residentialMortgages.form.yesLabel')}
                />
                {t('residentialMortgages.form.yesLabel')}
              </label>
              <label htmlFor="leverageHomeNo">
                <input
                  type="radio"
                  name="leverageHome"
                  value={t('residentialMortgages.form.noLabel') as string}
                  checked={formValues.leverageHome.value === noLabel}
                  onChange={handleRadioChange}
                  aria-label={t('residentialMortgages.form.noLabel')}
                />
                {t('residentialMortgages.form.noLabel')}
              </label>
            </div>
          </div>
          <Input
            id="email"
            isInvalid={formValues.email.isInvalid}
            validationMessage={formValues.email.validationMessage}
            options={{
              type: InputType.TEXT,
              label: t('residentialMortgages.form.email.label'),
              inputProps: {
                name: 'email',
                value: formValues.email.value,
              },
            }}
            required
            onChange={handleFormChange}
          />
          <TextArea
            id="message"
            isInvalid={formValues.message.isInvalid}
            validationMessage={formValues.message.validationMessage}
            options={{
              label: t('residentialMortgages.form.message.label'),
              textAreaProps: {
                rows: 13,
                cols: 40,
                name: 'description',
                value: formValues.message.value,
              },
            }}
            required
            onChange={handleFormChange}
          />
          <div className="ResidentialMortgagesForm__recaptcha-wrapper">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.GATSBY_WEB_TO_CASE_SALESFORCE_CAPTCHA_KEY_VALUE || ''}
              onChange={handleCaptchaOnChange}
              hl={activeLocaleTag}
              theme="dark"
            />
          </div>

          {formSubmissionResponse.shouldDisplayMessage && !formSubmissionResponse.submitted && (
            <ErrorMessage className="ResidentialMortgagesForm__generic-error-message">
              {t('residentialMortgages.form.submissionValidation.error')}
            </ErrorMessage>
          )}

          {areFormValuesInvalid && (
            <ErrorMessage className="ResidentialMortgagesForm__generic-error-message">
              {t('residentialMortgages.form.genericErrorMessage')}
            </ErrorMessage>
          )}

          <Button
            id="contact-submit"
            type="submit"
            iconOptions={{ icon: IconTypes.SEND_MAIL }}
            styleOptions={{ isInline: true }}
          >
            {t('residentialMortgages.form.submitLabel')}
          </Button>
        </>
      ) : null}
      {formValues.htbClient.value === noLabel ? (
        <div className="ResidentialMortgagesForm__generic-gap">
          <p>{t('residentialMortgages.form.findBroker.description')}</p>
          <ButtonLink
            to={CommonData.websites.findBroker[activeLocaleTag]}
            linkOptions={{ isExternalLink: true }}
            styleOptions={{ size: ButtonLinkSize.SMALL, isInline: true }}
            iconOptions={{ icon: IconTypes.ARROW_NEXT }}
          >
            {t('residentialMortgages.form.findBroker.buttonLabel')}
          </ButtonLink>
        </div>
      ) : null}
    </form>
  );
};

export default ResidentialMortgagesForm;
