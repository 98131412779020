import RouteKeys from '../../../config/RouteKeys';
import { PageMenuLinks } from './pageMenuTypes';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';

const residentialMortgagesMenu: PageMenuLinks = [
  {
    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.RESIDENTIAL_MORTGAGES.RENEW_YOUR_MORTGAGE),
    textKey: 'pageTitles.renewMortgage',
  },
  {
    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.RESIDENTIAL_MORTGAGES.REFINANCING_MORTGAGE),
    textKey: 'pageTitles.refinancingMortgage',
  },
  {
    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.RESIDENTIAL_MORTGAGES.PURCHASING_HOME),
    textKey: 'pageTitles.purchasingHome',
  },
];

export default residentialMortgagesMenu;
