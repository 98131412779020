import React, { FormEvent, HTMLProps } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { LocaleNameSpaceKeys } from '../../../localization/translations';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import './style.scss';

export interface TextAreaOptions {
  label: string;
  textAreaProps?: HTMLProps<HTMLTextAreaElement>;
}

export interface SelectOption {
  label: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
}

interface OwnProps {
  id: string;
  className?: string;
  options: TextAreaOptions;
  isInvalid?: boolean;
  validationMessage?: string;
  onChange?: (...args: any[]) => any;
  required?: boolean;
}

type Props = OwnProps;

const TextArea: React.FC<Props> = ({
  className = '',
  id,
  options,
  onChange = () => {},
  isInvalid,
  validationMessage = '',
  required,
}) => {
  const { t } = useTranslation();

  const handleOnChange = (event: FormEvent<HTMLTextAreaElement>) => {
    onChange(event.currentTarget.value, id);
  };

  const errorMessage =
    !!isInvalid && validationMessage ? (
      <ErrorMessage id={`error-${id}`}>{validationMessage}</ErrorMessage>
    ) : null;

  const TextAreaClassNames = classNames('TextArea', className, {
    'TextArea__is-invalid': !!isInvalid,
  });

  const inputAria = validationMessage ? { 'aria-describedby': `error-${id}` } : {};
  const requiredLabel = t(`${LocaleNameSpaceKeys.COMMON}:requiredField`);
  const label = required ? `${options.label} ${requiredLabel}` : options.label;

  return (
    <div className={TextAreaClassNames}>
      <label htmlFor={id}>{label}</label>
      <div className="TextArea__group-wrapper">
        <textarea id={id} onChange={handleOnChange} {...options.textAreaProps} {...inputAria} />
      </div>
      {errorMessage}
    </div>
  );
};

export default TextArea;
